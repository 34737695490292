import React from 'react'
import PropTypes from 'prop-types'

const SortButton = ({
  onClick,
  status
}) => {
  let iconClass
  switch (status) {
    case 'asc':
      iconClass = 'fa-sort-down'; break
    case 'desc':
      iconClass = 'fa-sort-up'; break
    default:
      iconClass = 'fa-sort';
  }

  return(
    <i
      className={`fa ${iconClass}`}
      onClick={onClick}
    />
  )
}

SortButton.propTypes = {
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['asc', 'desc'])
}

export default SortButton
