import { Controller } from "@hotwired/stimulus"

const minutesToMiliseconds = (minutes) => minutes * 60 * 1000;

// Connects to data-controller="shared--session-timeout"
export default class extends Controller {
  static values = { timeoutMinutes: Number, warningMinutes: Number}
  static targets = [ "dialog", "warning", "logout" ]

  connect() {
    this.resetTimer()
    $(document).ajaxComplete(() => this.resetTimer());
  }

  resetTimer() {
    this.dialogTarget.style.display = 'none';
    this.warningTarget.style.display = 'none';
    this.logoutTarget.style.display = 'none';

    clearTimeout(this.warningTimeout)
    clearTimeout(this.logoutTimeout)

    this.startTimer()
  }

  startTimer() {
    const minutesBeforeWarning = this.timeoutMinutesValue - this.warningMinutesValue;

    this.warningTimeout = setTimeout(() => {
      this.dialogTarget.style.display = 'flex';
      this.warningTarget.style.display = 'flex';
      this.logoutTarget.style.display = 'none';

      this.logoutTimeout = setTimeout(() => {
        this.warningTarget.style.display = 'none';
        this.logoutTarget.style.display = 'flex';
      }, minutesToMiliseconds(this.warningMinutesValue));

    }, minutesToMiliseconds(minutesBeforeWarning));
  }

  disconnect() {
    clearTimeout(this.warningTimeout)
    clearTimeout(this.logoutTimeout)
  }
}
