import React from 'react'
import classname from 'classname'
import _ from 'lodash'
import SortButton from 'components/sort_button'

const AssetLibraryAccessesTable = ({
  users,
  onDelete,
  onUpdate,
  filter,
}) => {
  const [sortColumn, setSortColumn] = React.useState('lastName')
  const [sortDirection, setSortDirection] = React.useState('asc')

  const sortBy = column => {
    const direction = sortColumn === column && sortDirection === 'asc'
      ? 'desc' : 'asc'
    setSortColumn(column)
    setSortDirection(direction)
  }

  const sortStatusFor = column => (
    sortColumn === column ? sortDirection : null
  )

  if (filter) {
    const regex = new RegExp(filter, 'i')
    const attrs = ['firstName', 'lastName', 'email', 'companyTitle']
    users = users.filter(
      user => _.some(attrs, attr => user[attr].match(regex))
    )
  }

  if (sortColumn) {
    users = _.sortBy(users, [sortColumn])
    if(sortDirection === 'desc') {
      users = _.reverse(users)
    }
  }

  return (
    <div className="ClientCodeForm__clientsTable">
      <table>
        <thead>
          <tr>
            <th>
              <span onClick={() => sortBy('firstName')} className="sortable">
                Name
                <SortButton  status={sortStatusFor('firstName')}/>
              </span>
            </th>
            <th>
              <span onClick={() => sortBy('lastName')} className="sortable">
                Last
                <SortButton status={sortStatusFor('lastName')}/>
              </span>
            </th>
            <th>
              <span onClick={() => sortBy('email')} className="sortable">
                Email
                <SortButton status={sortStatusFor('email')}/>
              </span>
            </th>
            <th>
              <span onClick={() => sortBy('companyTitle')} className="sortable">
                Title
                <SortButton status={sortStatusFor('companyTitle')}/>
              </span>
            </th>
            <th className="icon">
              Download
            </th>
            <th className="icon">
              Full Res Video Download
            </th>
            <th className="icon">
              Remove
            </th>
          </tr>
        </thead>

        <tbody>
          { users.map( user => (
              <tr key={ user.id }>
                <td> { user.firstName } </td>
                <td> { user.lastName } </td>
                <td>
                  <a href={ `mailto:${user.email}` }> {user.email} </a>
                </td>
                <td> { user.companyTitle } </td>
                <td className="icon">
                  <div className="Icon">
                    <i
                      className={
                        classname({
                          fa: true,
                          ClientCodeForm__toggleDownloadAllowed: true,
                          'fa-toggle-on': user.downloadAllowed,
                          'fa-toggle-off': !user.downloadAllowed
                        })
                      }
                      onClick={() => onUpdate(user.id, { downloadAllowed: !user.downloadAllowed })}
                    />
                  </div>
                </td>
                <td className="icon">
                  <div className="Icon">
                    <i
                      className={
                        classname({
                          fa: true,
                          ClientCodeForm__toggleDownloadAllowed: true,
                          'fa-toggle-on': user.fullResVideoDownloadAllowed,
                          'fa-toggle-off': !user.fullResVideoDownloadAllowed
                        })
                      }
                      onClick={() => onUpdate(user.id, { fullResVideoDownloadAllowed: !user.fullResVideoDownloadAllowed })}
                    />
                  </div>
                </td>
                <td className="icon">
                  <div className="Icon">
                    <i
                      className="fa fa-trash ClientCodeForm__deleteClient"
                      onClick={() => onDelete(user.id)}
                    />
                  </div>
                </td>
              </tr>
            ))
          }
          { _.isEmpty(users) &&
            <tr>
              <td colSpan="6" rowSpan="2" className="ClientCodeForm__emptyNotice">
                { filter
                    ? `No clients match the search for "${filter}".`
                    : `There are no Client Users assigned. To add one use the search bar in the top right.`
                }
              </td>
            </tr>
          }
        </tbody>
      </table>

      { users.map( (user, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              name={`client_code_form[asset_library_accesses][${index}][user_id]`}
              value={user.id}
            />
            <input
              type="hidden"
              name={`client_code_form[asset_library_accesses][${index}][download_allowed]`}
              value={user.downloadAllowed}
            />
            <input
              type="hidden"
              name={`client_code_form[asset_library_accesses][${index}][full_res_video_download_allowed]`}
              value={user.fullResVideoDownloadAllowed}
            />
          </React.Fragment>
      ))}
      { (users.length === 0) &&
        <input type="hidden" name="client_code_form[asset_library_accesses]" value="_empty"/>
      }
    </div>
  )
}

export default AssetLibraryAccessesTable
